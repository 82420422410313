import React from 'react';
import Logo from './logo.png';

const IconLogo = () => (
//   <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
//  width="96.000000pt" height="96.000000pt" viewBox="0 0 96.000000 96.000000"
//  preserveAspectRatio="xMidYMid meet">
// <metadata>
// Created by potrace 1.15, written by Peter Selinger 2001-2017
// </metadata>
// <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
// fill="#000000" stroke="none">
// </g>
// </svg>
  <img src={Logo} alt="logo" />
);

export default IconLogo;
